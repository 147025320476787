import Hero from './components/Hero'
function App() {
  return (
    <div className="App">
    <Hero/>
    </div>
  );
}

export default App;
