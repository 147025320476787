import itEdu from "../assets/it-edu.svg";
import gisTech from "../assets/gis-tech.svg";
import itDev from "../assets/it-dev.svg";
import pm from "../assets/pm.svg";
import itAudit from "../assets/it-audit.svg";
import itExport from "../assets/it-export.svg";
import instagram from "../assets/instagarm.svg";
import facebook from "../assets/facebook.svg";
import linkedin from "../assets/linkedin.svg";
import telegram from "../assets/telegram.svg";
export default function Hero() {
  return (
    <div className="hero-container w-full h-full min-h-[100vh] text-white text-center">
      <div className="hero-inner w-full h-full min-h-[100vh] p-10 sm:p-[100px] flex justify-center items-center flex-col">
        <h1 className="hero-title text-3xl sm:text-4xl md:text-5xl font-medium leading-8 tracking-wide mb-6 md:mb-8 uppercase">
          MAXCYBERCOM
        </h1>
        <p className="hero-description text-lg sm:text-xl md:text-2xl leading-6 mb-8 md:mb-12 uppercase">
          Milliy raqamlashtirish kompaniyasi
        </p>
        <div className="hero-card w-auto md:w-[650px] h-auto md:h-[450px] py-10 md:py-16 px-8 md:px-14 bg-[#5e608290] grid grid-cols-2 md:grid-cols-3 gap-8 mb-10 md:mb-16">
          <div className="item flex flex-col items-center justify-center cursor-pointer hover:opacity-70 hover:scale-105">
            <img
              className="mb-4"
              src={itEdu}
              alt="IT TA'LIM"
              width={50}
              height={65}
            />
            <p className="text-xl uppercase text-center leading-8 tracking-wider">
              IT Ta'lim
            </p>
          </div>
          <div className="item flex flex-col items-center justify-center cursor-pointer hover:opacity-70 hover:scale-105">
            <img
              className="mb-4"
              src={gisTech}
              alt="GIS TECH"
              width={55}
              height={55}
            />
            <p className="text-xl uppercase text-center leading-8 tracking-wider">
              GIS TECH
            </p>
          </div>
          <div className="item flex flex-col items-center justify-center cursor-pointer hover:opacity-70 hover:scale-105">
            <img
              className="mb-4"
              src={itDev}
              alt="IT DEV"
              width={57}
              height={57}
            />
            <p className="text-xl uppercase text-center leading-8 tracking-wider">
              IT DEV
            </p>
          </div>
          <div className="item flex flex-col items-center justify-center cursor-pointer hover:opacity-70 hover:scale-105">
            <img className="mb-4" src={pm} alt="PM" width={57} height={57} />
            <p className="text-xl uppercase text-center leading-8 tracking-wider">
              PM
            </p>
          </div>
          <div className="item flex flex-col items-center justify-center cursor-pointer hover:opacity-70 hover:scale-105">
            <img
              className="mb-4"
              src={itAudit}
              alt="IT AUDIT"
              width={58}
              height={58}
            />
            <p className="text-xl uppercase text-center leading-8 tracking-wider">
              IT Audit
            </p>
          </div>
          <div className="item flex flex-col items-center justify-center cursor-pointer hover:opacity-70 hover:scale-105">
            <img
              className="mb-4"
              src={itExport}
              alt="IT EXPORT"
              width={57}
              height={57}
            />
            <p className="text-xl uppercase text-center leading-8 tracking-wider">
              IT Export
            </p>
          </div>
        </div>
        <div className="w-full socials flex items-center justify-center gap-x-10 sm:gap-x-14">
          <div className="social cursor-pointer hover:scale-110">
            <a href="https://t.me/maxcyber_com" className="socila-link">
              <img src={telegram} alt="Telegram" className="social-img" width={26} height={26}/>
            </a>
          </div>
          <div className="social cursor-pointer hover:scale-110">
            <a href="https://facebook.com/maxcybercom.uz" className="socila-link">
              <img src={facebook} alt="Facebook" className="social-img" width={12} height={26}/>
            </a>
          </div>
          <div className="social cursor-pointer hover:scale-110">
            <a href="https://www.linkedin.com/company/maxcybercom/" className="socila-link">
              <img src={linkedin} alt="Linkedin" className="social-img" width={28} height={28}/>
            </a>
          </div>
          <div className="social cursor-pointer hover:scale-110">
            <a href="https://t.me/maxcybercom" className="socila-link">
              <img src={instagram} alt="Instagarm" className="social-img" width={26} height={26}/>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
